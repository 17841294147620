<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">新着情報の管理</h1>
        <banner-hint>
          ホーム画面に表示できる新着情報パーツを使ってお客様にいち早くお知らせたい事を内容問わず通知できます。<br />
          イベント情報・新人情報は別のホーム画面パーツとしてもありますが、内容をまとめたり自由に編集したい場合などに新着情報パーツを使ってください。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- 新規追加ボタン -->
        <div class="mb-7">
          <v-btn
            depressed
            color="primary"
            @click="addNews()"
          >新着情報を追加</v-btn>
        </div>

        <v-card v-if="!newsRecords.length" flat>
          <v-card-text>新着情報が登録されていません。</v-card-text>
        </v-card>

        <v-card
          class="my-5 pt-10 pb-7 px-6"
          elevation="1"
          v-for="(news, index) in newsRecords"
          :key="news.news_id"
        >
          <v-form :ref="'form-news-' + news.news_id">
            <!-- ******************************************** -->
            <!-- タイトル -->
            <!-- ******************************************** -->
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="news.title"
                  outlined
                  required
                  persistent-hint
                  label="見出し"
                  counter="30"
                  :hint="dateFormat(news.created_at) + ' 投稿'"
                  :rules="[valiRules.required, valiRules.max30]"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- ******************************************** -->
            <!-- 画像 -->
            <!-- ******************************************** -->
            <v-row class="mt-5">
              <v-col cols="6"
              >
                <v-sheet
                  class="mt-n5 mb-5"
                  v-if="news.image_url"
                >
                  <!-- サムネイル -->
                  <small>設定中の画像</small>
                  <v-img
                    max-height="600"
                    contain
                    :src="news.image_url"
                  >
                  </v-img>
                </v-sheet>
                <!-- インプット -->
                <v-file-input
                  v-model="updateImages[news.news_id]"
                  accept="image/png, image/jpeg"
                  outlined
                  chips
                  show-size
                  required
                  prepend-icon="mdi-image"
                  label="アイキャッチ画像"
                  hint="推奨の縦横サイズは[500px : 400px]程度の縦長"
                  persistent-hint
                  :rules="news.create ? [valiRules.required, valiRules.image] : [valiRules.image]"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      color="primary"
                      label small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>

              <!-- ******************************************** -->
              <!-- 本文 -->
              <!-- ******************************************** -->
              <v-col cols="6">
                <tiny-editor
                  v-if="contentStyle"
                  v-bind:text.sync="news.text"
                  :apiAdmin="apiAdmin"
                  :shopData="shopData"
                  :contentStyle="contentStyle"
                  :max="textMax"
                  :height="650"
                />
                <small
                  v-if="newsRecords.length == 1"
                >背景・文字は実際のHPのカラーです</small>
              </v-col>
            </v-row>

            <!-- ボタン -->
            <v-row no-gutters class="mt-5 justify-end">
              <div>
                <v-btn
                  v-if="news.create"
                  depressed small
                  color="primary"
                  @click="createRow(news)"
                >登録</v-btn>
                <v-btn
                  v-else
                  depressed small
                  color="primary"
                  @click="updateRow(news)"
                >更新</v-btn>
                <v-btn
                  class="ml-2"
                  text
                  depressed small
                  color="primary"
                  @click="deleteRow(index)"
                >削除</v-btn>
              </div>
            </v-row>
          </v-form>
        </v-card>

        <!-- もっとボタン -->
        <div
          v-if="newsRecords.length >= this.queryLimit"
        >
          <v-btn
            :disabled="disableLoadMore"
            color="primary"
            @click="loadMore"
          >更に読み込む
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from "moment";
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";
import TinyEditor from "@/components/_TinyEditor.vue"

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'tiny-editor': TinyEditor,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxNewsId: 0,
      newsRecords: [],
      textMax: 5000,
      updateImages: [],
      queryLimit: 5,
      queryOffset: 0,
      disableLoadMore: false,
      contentStyle: '',
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    dateFormat() {
      return date => moment(date).format('YYYY/MM/DD HH:mm')
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getSiteNews(),
        this.getSiteSetting(),
      ])

      this.loading = false
    },

    //もっと読み込む
    loadMore() {
      this.getSiteNews()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //新規追加
    addNews() {
      this.newsRecords.unshift({
        news_id: ++this.maxNewsId,
        title: '',
        text: '',
        image_url: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //
    getSiteNews() {
      const query = {limit: this.queryLimit, offset: this.queryOffset}

      return this.publicApi.getReqSitePublic('common/news/', query).then( records => {
        if (!records || !records.length) {
          this.disableLoadMore = true
          return
        }

        //id最大値の取得
        const maxRecordId = Math.max(...records.map( row => parseInt(row.news_id) ))
        this.maxNewsId = maxRecordId > this.maxNewsId ? maxRecordId : this.maxNewsId

        records.map( row => { this.newsRecords.push(row) })
        this.queryOffset = this.queryOffset + this.queryLimit
      })
    },

    // API req: site_setting
    getSiteSetting() {
      return this.adminApi.getReqWithAuth('site-setting/').then( data => {
        if (!data) return
        this.contentStyle = 'body#tinymce {color: ' + (data.is_dark_theme ? 'white' : 'black') +
                            '; background-color: ' + (data.secondary_color || 'grey') + ';}'
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(news) {
      if (!this.$refs['form-news-' + news.news_id][0].validate() ||
        !news.text || !news.title || !this.updateImages[news.news_id]
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append('title', news.title)
      formData.append('text', news.text)
      formData.append('image_url', this.updateImages[news.news_id])

      this.adminApi.apiReqWithData('POST', 'create/site-news/', formData).then( response => {
        news.image_url = response.image_url
        news.news_id = response.news_id
        delete this.updateImages[news.news_id]
        news.create = false
        this.queryOffset++

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(news) {
      if (!this.$refs['form-news-' + news.news_id][0].validate() ||
        !news.text || !news.title || (!news.image_url && !this.updateImages[news.news_id])
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/site-news/' + news.news_id
      const formData = new FormData()
      formData.append('title', news.title)
      formData.append('text', news.text)
      formData.append('image_url', this.updateImages[news.news_id] || news.image_url)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
          return
        }

        delete this.updateImages[news.news_id]
        news.image_url = response.image_url

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.newsRecords[index].create) {
        this.newsRecords.splice(index, 1)
        return
      }

      const apiPath = 'delete/site-news/' + this.newsRecords[index].news_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.newsRecords.splice(index, 1)
        this.queryOffset--

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
</style>
