var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('editor',{attrs:{"api-key":_vm.editorKey,"value":_vm.text,"init":{
      body_class: 'tiny-editor',
      language: 'ja',
      menubar: false,
      height: _vm.$attrs.height,
      content_style: _vm.$attrs.contentStyle,
      images_upload_handler: _vm.uploadImage,
      plugins: [
        'code lists link image imagetools preview',
        'searchreplace fullscreen emoticons template'
      ],
      toolbar:
        'formatselect | bold italic forecolor backcolor | \
        alignleft aligncenter alignright | image | emoticons | link | \
        bullist numlist outdent indent | removeformat | code fullscreen preview | ',
    }},on:{"input":function($event){return _vm.$emit('update:text', $event)}}}),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":"","justify":"end","align":"center"}},[_c('span',{staticClass:"text-body-2 text--disabled"},[_vm._v(_vm._s(_vm.textCount))]),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("（タグ等のデザイン情報を含む文字数です）")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }