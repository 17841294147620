<template>
  <v-container class="pa-0">
    <editor
      :api-key="editorKey"
      :value="text"
      @input="$emit('update:text', $event)"
      :init="{
        body_class: 'tiny-editor',
        language: 'ja',
        menubar: false,
        height: $attrs.height,
        content_style: $attrs.contentStyle,
        images_upload_handler: uploadImage,
        plugins: [
          'code lists link image imagetools preview',
          'searchreplace fullscreen emoticons template'
        ],
        toolbar:
          'formatselect | bold italic forecolor backcolor | \
          alignleft aligncenter alignright | image | emoticons | link | \
          bullist numlist outdent indent | removeformat | code fullscreen preview | ',
      }"
    />
    <v-row no-gutters class="mt-1"
      justify="end" align="center"
    >
      <span class="text-body-2 text--disabled">{{ textCount }}</span>
      <span class="text-caption text--disabled">（タグ等のデザイン情報を含む文字数です）</span>
    </v-row>
  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ApiTool } from '@/module.js'
import Editor from '@tinymce/tinymce-vue'

export default {
  inheritAttrs: false,

  components: {
    'editor': Editor,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editorKey: '260ne5ramb8v1eck3vyrzbxmaj1dx5792e1zoy6bx5h8fm17',
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    textCount() {
      return this.text.length + ' / ' + this.max
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)
  },

  methods: {
    // image upload handler for Editor
    uploadImage(blobInfo, success, failure) {
      if (blobInfo.blob().size > 1 * 1024 * 1024) {
        failure($literals.MESSAGE.validationFileSize)
        return
      }

      const formData = new FormData()
      formData.append('upload_image', blobInfo.blob())
      formData.append('name', blobInfo.filename())

      this.adminApi.apiReqWithData('POST', 'upload/image/', formData).then( response => {
        success(response)
      })
      .catch(() => failure($literals.MESSAGE.failedUploadFile))
    }
  }
};
</script>

<style scoped>
>>> .tox .tox-toolbar__primary {
  background-color: var(--content-bg-diff);
}
</style>
